<template>
  <div class="px-10 py-5 text-center grey lighten-1">
    <slick ref="slick" :options="slickOptions">
      <div v-for="articulo of articulos" :key="articulo.id" class="px-4">
        <Articulo
				:id="articulo.id"
          :nombre="articulo.nombre"
          :imagen="articulo.imagen"
          :precio="articulo.precio"
          :descripcion="articulo.descripcion"
          :etiqueta="articulo.etiqueta"
          :home="home"
        />
      </div>
    </slick>
  </div>
</template>

<script>
import "jquery/package.json";
import Slick from "vue-slick";
import Articulo from "../components/Articulo.vue";
export default {
  data() {
    return {
      dialog: false,
      slickOptions: {
        autoplay: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 730,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  props: {
    home: {
      type: Boolean,
      default: false,
    },
    articulos: {
      type: Array,
    },
  },
  components: {
    Slick,
    Articulo,
  },
};
</script>
